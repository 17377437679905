<template>
  <div class="SNOWS-common-layout">
    <CommonTree
      :treeData="treeData"
      :defaultProps="defaultProps"
      :searchTitle="searchTitle"
      treeTitle="流程分类"
      :isShowdig="true"
      :showCheckbox="false"
      @getTreeAdd="flowCate =true"
      @getTreeEdit="getTreeEdit"
      @getTreeDelete="getTreeDelete"
      @getNodeClick="getNodeClick"
    />
    <div class="SNOWS-common-layout-center">
      <head-layout head-title="流程设计" :head-btn-options="headBtnOptions" @head-add="addFlow(1)"/>
      <el-row class="SNOWS-common-search-box" :gutter="16" style="margin-bottom: 0">
        <el-form @submit.native.prevent>
          <el-col :span="4">
            <el-form-item>
              <el-input v-model="fullName" placeholder="请输入流程名称查询"
                        clearable @keyup.enter.native="search()" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item>
              <el-input v-model="enCode" placeholder="请输入流程编码查询"
                        clearable @keyup.enter.native="search()" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-search" @click="search()"></el-button>
              <el-button size="mini" icon="reset-refresh icon-refresh" @click="reset()"></el-button>
            </el-button-group>
          </el-col>
        </el-form>
      </el-row>
      <div class="SNOWS-common-layout-main SNOWS-flex-main" style="padding: 0 12px 12px 12px">
        <SNOWSTable v-loading="listLoading" :data="list">
          <el-table-column align="center" prop="fullName" label="流程名称" min-width="150" />
          <el-table-column align="center" prop="enCode" label="流程编码" width="200" />
          <el-table-column align="center" prop="category" label="流程分类"  />
          <el-table-column align="center" prop="type" label="流程类型" >
            <template slot-scope="scope">
              <span>{{ scope.row.type == 0? "发起流程" : "功能流程" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="visibleType" label="可见范围" >
            <template slot-scope="scope">
              <span>{{ scope.row.visibleType ==  0 ? "全部可见" : "部分可见" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="left" prop="sortCode" label="排序" width="70" />
          <el-table-column align="center" prop="creatorUser" label="创建人"  />
          <el-table-column align="left" prop="version" label="版本号" width="70">
            <template slot-scope="scope">
              <el-tag>V:{{scope.row.version}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="enabledMark" label="状态" width="70" >
            <template slot-scope="scope">
              <el-tag :type="scope.row.enabledMark == 1 ? 'success' : 'danger'" disable-transitions>
                {{scope.row.enabledMark==1?'启用':'禁用'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" fixed="right" width="200">
            <template slot-scope="scope">
              <tableOpts @edit="addOrUpdateHandle(scope.row.id,scope.row.type)"
                         @del="handleDel(scope.row.id)">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    <el-button type="text" size="mini">{{'更多'}}<i
                      class="el-icon-arrow-down el-icon--right"></i>

                    </el-button>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="scope.row.enabledMark!=1"
                                      @click.native="handleUpdate(scope.row)">
                      启用流程</el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.enabledMark==1"
                                      @click.native="handleUpdate(scope.row)">
                      禁用流程</el-dropdown-item>
                    <el-dropdown-item @click.native="showManage(scope.row.id,scope.row.fullName)">
                      版本管理</el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.hasAssistBtn==1"
                                      @click.native="management(scope.row.id)">
                      协管流程</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </tableOpts>
            </template>
          </el-table-column>
        </SNOWSTable>
        <pagination :total="total" :page.sync="listQuery.currentPage"
                    :limit.sync="listQuery.pageSize" @pagination="initData" />
      </div>

    </div>
    <Form v-if="formVisible" ref="Form" @close="closeForm" />
    <FlowManage v-if="manageVisible" ref="FlowManage" @close="closeManage" />
    <el-dialog title='协管流程' :close-on-click-modal="false" :visible.sync="managementVisible"
               class="SNOWS-dialog SNOWS-dialog_center" lock-scroll append-to-body width='600px'>
      <el-form ref="dataForm" label-width="100px">
        <el-form-item label="设置协管员">
          <user-select multiple v-model="managementUserId" placeholder="请选择该流程协管人员" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="managementVisible = false">取消</el-button>
        <el-button type="primary" @click="handleApproval()" :loading="btnLoading">
          确定
        </el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'流程分类'" :visible.sync="flowCate"
      :close-on-click-modal="false"
      lock-scroll width="600px">

      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="分类名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="分类编码">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="上级分类">
          <el-select v-model="form.pid" placeholder="请选择活动区域">
            <el-option>
              <el-tree ref="tree" :data="treeData" default-expand-all node-key="id" default-check :props="defaultProps" @node-click="handleNodeClick" />
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flowCate = false">取消</el-button>
        <el-button type="primary" @click="getTreeAdd()" :loading="btnLoading">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FlowEngineList,saveOrUpdate,DeleteFlowCate, Delete,FlowCate, Release, Stop, Copy, exportData, assist, assistList } from '@/api/workFlow/FlowEngine'
import Form from './Form'
import FlowManage from './FlowManagement.vue'
import topOpts from '@/components/SNOWS-topOperation'
import tableOpts from '@/components/SNOWS-tableOperation'
import SNOWSTable from "@/components/SNOWS-table";
import CommonTree from "@/views/components/com_tree/index"
export default {
  name: 'workFlow-flowEngine',
  components: {SNOWSTable, Form, FlowManage,topOpts,tableOpts,CommonTree },
  data() {
    return {
      flowCate: false,
      treeData:[],
      defaultProps:{
        label: "name",
        value: "id"
      },
      nodeData:{},
      tmpId:'',
      form:{},
      flag:false,
      searchTitle:'name',
      headBtnOptions:[
        {
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: "",
          btnOptType: 'add',
        }
      ],
      keyword: '',
      fullName: '',
      enCode: '',
      category: '',
      listQuery: {
        currentPage: 1,
        pageSize: 20,
        sort: 'desc',
        sidx: ''
      },
      total: 0,
      list: [],
      btnLoading: false,
      listLoading: true,
      dialogVisible: false,
      formVisible: false,
      manageVisible: false,
      categoryList: [],
      managementVisible: false,
      managementUserId: [],
      templateId: ''
    }
  },
  created() {
    this.initData()
    this.initTreeData()
  },
  methods: {
    handleNodeClick(data,node,nodeData){
      this.tmpId = data.id
      this.nodeData.pid = data.id
      this.form.pid = data.name
    },
    getTreeAdd(){
      if(!this.form.pid) this.form.pid = 0
      this.form.pid = this.tmpId
      saveOrUpdate(this.form).then(res=>{
        console.log(res.data)
        this.form = {}
        this.initTreeData()
        this.flowCate = false
        localStorage.setItem("flowCateTree",undefined)
      })
    },
    getTreeEdit(){
      this.form = this.nodeData
      this.form.pid = this.treeForeach(this.nodeData.pid)&&this.treeForeach(this.nodeData.pid).name//parentNodeName
      if(this.form.pid == 0 ) this.form.pid = ''
      this.flowCate = true
    },
    getTreeDelete(){
      let param = {'id': this.nodeData.id}
      DeleteFlowCate(param).then(res =>{
        this.$message.success(res.data.msg)
        this.initTreeData()
        localStorage.setItem("flowCateTree",undefined)
      })
    },
    getNodeClick(nodeData){
      this.nodeData = nodeData
      this.form.pid = nodeData.name
      this.category = nodeData.id
      this.initData()
    },
    initTreeData(){
      FlowCate({}).then(res=>{
        this.treeData = res.data.data
      })
    },
    reset() {
      this.keyword = ''
      this.fullName = ''
      this.enCode = ''
      this.category = ''
      this.search()
    },
    search() {
      this.listQuery = {
        currentPage: 1,
        pageSize: 10,
        sort: 'desc',
        sidx: ''
      }
      this.initData()
    },
    initData() {
      this.listLoading = true
      let query = {
        ...this.listQuery,
        keyword: this.keyword,
        fullName: this.fullName,
        enCode: this.enCode,
        category: this.category
      }
      FlowEngineList(this.listQuery.currentPage,this.listQuery.pageSize,query).then((res) => {
        this.list = res.data.data.records || []
        this.list.forEach(e => e.category = this.treeForeach(e.category)&&this.treeForeach(e.category).name)
        this.total = res.data.data.total || 0
        this.listLoading = false
      })
    },
    treeForeach (id) {
      let node, list = [...this.treeData]
      while (node = list.shift()) {
        if(node.id == id) return node
        node.children && list.push(...node.children)
      }
    },
    handleDel(id) {
      this.$confirm(this.$t('确认删除'), this.$t('删除'), {
        type: 'warning'
      }).then(() => {
        Delete(id).then(res => {
          this.initData()
          this.$message({
            type: 'success',
            message: res.data.msg
          });
        })
      }).catch(() => { });
    },
    addFlow(type) {
      this.dialogVisible = false
      this.addOrUpdateHandle('', type)
    },
    // 新增 / 修改
    addOrUpdateHandle(id, type) {
      this.formVisible = true
      this.$nextTick(() => {
        this.$refs.Form.init(this.treeData, id, type)
      })
    },
    copy(id) {
      this.$confirm('您确定要复制该流程, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        Copy(id).then(res => {
          this.$message({
            type: 'success',
            message: res.data.msg,
            duration: 1000,
            onClose: () => {
              this.initData()
            }
          });
        })
      }).catch(() => { });
    },
    handleExport(id) {
      this.$confirm('您确定要导出该流程, 是否继续?', '提示', {
        type: 'warning'
      }).then(() => {
        exportData(id).then(res => {
          this.snows.downloadFile(res.data.url)
        })
      }).catch(() => { });
    },
    closeForm(isRefresh) {
      this.formVisible = false
      if (isRefresh) {
        this.initData()
      }
    },
    showManage(id, fullName) {
      this.manageVisible = true
      this.$nextTick(() => {
        this.$refs.FlowManage.init(id, fullName)
      })
    },
    closeManage(isRefresh) {
      this.manageVisible = false
      if (isRefresh) {
        this.initData()
      }
    },
    handleApproval() {
      let query = {
        list: this.managementUserId,
        templateId: this.templateId
      }
      assist(query).then(res => {
        this.$message({
          type: 'success',
          message: res.data.msg
        });
        this.managementVisible = false
        this.initData()
      })
    },
    management(id) {
      this.managementVisible = true
      this.templateId = id
      assistList(id).then(res => {
        this.managementUserId = res.data.list || []
      })
    },
    handleUpdate(row) {
      if (row.enabledMark) {
        this.$confirm('此操作将禁用该流程，是否继续？', '提示', {
          type: 'warning'
        }).then(() => {
          Stop(row.id).then(res => {
            row.enabledMark = 0
            this.$message({
              type: 'success',
              message: res.data.msg
            });
          })
        }).catch(() => { });
      } else {
        this.$confirm('此操作将启用该流程，是否继续？', '提示', {
          type: 'warning'
        }).then(() => {
          Release(row.id).then(res => {
            row.enabledMark = 1
            this.$message({
              type: 'success',
              message: res.data.msg
            });
          })
        }).catch(() => { });
      }
    },

  }
}
</script>
<style lang="scss" scoped>
 .pagination-container {
   padding-top: 10px;
   border-top: 1px solid #EBEEF5;
 }
</style>
